<template>
  <div class="customerManagement" v-if="hasResource('customer_info')">
    <change-status
      ref="changeStatusPop"
      @update="getCustomerData"
    ></change-status>
    <add-points ref="addPointsPop" @update="getDetailList"></add-points>
    <cancel-account ref="cancelAccountPop" @update="back"></cancel-account>

    <div class="user-data" v-loading="isLoading">
      <div
        class="cursor-pointer mg-b-40 width-107 height-46 mg-b-20 text-back"
        @click="
          back();
          sendMsg();
        "
      >
        <i class="iconfont fz-18 black">&#xe647;</i>
        <span class="back-text">返回</span>
      </div>

      <div class="user-profile">
        <img
          class="profile-img"
          src="../../../assets/images/profile-img.png"
          alt
        />
        <div class="profile-detail">
          <div class="fz-24">{{ customerData.username }}</div>
          <div class="mg-t-12">{{ customerData.email }}</div>
          <div class="status-display">
            {{ statusDisplay[customerData.status] }}
          </div>
           <div class="user-subscribe" v-if="`${customerData.subscribe}` === '1'">
              已关注
          </div>
        </div>

        <div class="profile-btns">
          <el-button
            class="black-btn btn-m"
            @click="changeCustomerStatus"
            v-if="hasResource('customer_change_status')"
            >状态变更</el-button
          >
          <el-button
            class="black-btn btn-m"
            @click="addPoints"
            v-if="
              customerData.status === 4 &&
              hasResource('customer_balance_adjustment')
            "
            >添加积分</el-button
          >
          <el-button
            class="white-btn btn-m"
            @click="cancelAccount"
            v-if="hasResource('customer_delete')"
            >注销账户</el-button
          >
        </div>
      </div>

      <div class="profile-info">
        <div>
          <div class="fw-700 fz-24 mg-b-22">基础信息</div>
          <div class="account-info">
            <div
              class="info-detail"
              v-for="(item, idx) in profileInfo"
              :key="idx"
            >
              <span class="mg-r-24">{{ item.label }}</span>
              <span
                :title="
                  customerData[item.value] ? customerData[item.value] : '无'
                "
                >{{
                  customerData[item.value] ? customerData[item.value] : "未填写"
                }}</span
              >
            </div>
          </div>
        </div>
        <div>
          <div class="fw-700 fz-24 mg-b-22">订阅信息</div>
          <div class="account-info" style="width: 478px">
            <div
              class="info-detail"
               style="width: 90%"
              v-for="(item, idx) in subscriptionInfo"
              :key="idx"
            >
              <span class="mg-r-24">{{ item.label }} {{item.text}}</span>
                <span v-if="item.label === '订阅名称'">
                  {{subscribeType}}
                </span>
                <span v-else-if="item.label === '购买订阅'">
                  {{customerData.subscribe_desc || '--'}}
                </span>
                <span v-else-if="item.label === '到期时间'">
                  {{subscribeExpiredAt}}
                </span>
                <span v-else>{{customerData[item.value]}}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="fw-700 fz-24 mg-b-22">行为信息</div>
          <div class="account-info">
            <div
              class="info-detail"
              v-for="(item, idx) in actionInfo"
              :key="idx"
            >
              <span class="mg-r-24">{{ item.label }}</span>
              <span
                v-if="item.label === '下载次数'"
                :title="downloadCount"
                >{{downloadCount}}
              </span>
              <span
                v-else-if="item.label === '累计下载次数'"
                :title="totalDownloadCount"
                >{{totalDownloadCount}}
              </span>
              <span
                v-else
                :title="
                  customerData[item.value] ? customerData[item.value] : '无'
                "
                >{{
                  customerData[item.value] ? customerData[item.value] : "无"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="additional-info mg-b-40" v-if="customerData.note">
        <span class="mg-r-24">备注</span>
        <div class="note-content" v-html="formatHtml(customerData.note)"></div>
      </div>

      <div class="mg-t-18 mg-b-45">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :name="item.name"
            v-for="(item, idx) in filteredTabs"
            :key="idx"
            :label="item.name"
          >
            <el-table
              header-cell-class-name="table-head"
              cell-class-name="table-cell"
              :data="item.data"
              row-key="id"
              ref="dragTable"
              v-loading="item.tableLoading"
              height="400px"
              width="100%"
              v-if="hasResource(item.permission)"
            >
              <el-table-column
                v-for="col in item.tableColumns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :width="col.width"
                draggable="true"
              >
                <template slot-scope="{ row }">
                  <div v-if="col.prop == 'type'">
                   <span>{{getType(row.type, activeName)}}</span>
                  </div>
                  <div v-else-if="col.prop == 'client'">
                    {{ row.client ? "Web" : "桌面" }}
                  </div>
                  <div v-else-if="col.prop == 'relation_order'">
                    {{ row.relation_order || "暂无" }}
                  </div>
                  <div v-else-if="col.prop == 'purchase_plan_id'">
                    {{ row.purchase_plan_id || "暂无" }}
                  </div>
                  <div v-else-if="col.prop == 'payment_method'">
                      <span>{{paymentMethod[ Number(row.payment_method)]}}</span>
                  </div>
                  <div v-else-if="col.prop == 'purchase_plan_name'">
                    {{
                      row.purchase_plan_id
                        ? row.purchase_plan_id === 1
                          ? "个人版"
                          : row.purchase_plan_id === 2
                          ? "专业版"
                          : row.purchase_plan_id === 3
                          ? "企业版"
                          : "暂无"
                        : "暂无"
                    }}
                  </div>
                  <div v-else-if="col.prop === 'preview'">
                    <img class="previewImg" :src="row.preview+ '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'" alt />
                  </div>
                  <div  v-else-if="col.prop === 'create_at' || col.prop === 'purchased_at' || col.prop === 'created_at' || col.prop === 'expired_at'">
                  {{setCreate(row[col.prop])}}
                  </div>
                  <div v-else-if="col.prop === 'channel'">
                  {{setChannel(row)}}</div>
                  <div v-else>{{ row[col.prop] }}</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="ta-r mg-t-18">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 50]"
                :page-size="item.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="item.total"
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/customer";

import vars from "./vars";
import { changeStatus, addPoints, cancelAccount } from "./dialogs/index.js";
import customer from "@/mixins/customer";
import moment from "moment";

export default {
  components: {
    ChangeStatus: changeStatus,
    AddPoints: addPoints,
    CancelAccount: cancelAccount,
  },
  mixins: [customer],
  data() {
    return {
      customerID: "",
      header: {
        url: "userData",
      },
      paymentMethod:{
          1: '微信',
          2: '支付宝'
      },
      customerData: {},
      tableLoading: false,
      isLoading: false,
      tabPane: vars.tabPane,
      activeName: "积分变动记录",
      statusDisplay: vars.statusDisplay,
      profileInfo: vars.profileInfo,
      actionInfo: vars.actionInfo,
      subscriptionInfo: vars.subscriptionInfo,
    };
  },

  computed: {
    filteredTabs() {
      const tabs = this.tabPane;
      let state = false;
      const filtered = tabs.filter((item) => {
        if (this.hasResource(item.permission)) {
          state = !state ? item.name : state;
          // this.getDetailList();
        }
        return this.hasResource(item.permission);
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.activeName = state || this.activeName;
      return filtered;
    },
    downloadCount() {
      const downloadCount = this.customerData.subscription_info?.download_count;
      if (!downloadCount) {
        return '无';
      }
      return downloadCount;
    },
    totalDownloadCount() {
      const downloadTotal = this.customerData.subscription_info?.download_total;
      if (!downloadTotal) {
        return '无';
      }
      return downloadTotal;
      // 已购资产
    },
    subscribeType() {
      const subscriptionInfo = this.customerData.subscription_info;
      if (!subscriptionInfo) {
        return;
      }

      if (subscriptionInfo.subscription_type === 0) {
        return '无';
      }
      
      const subscriptionType = {
        "110":"个人版",
        "120":"专业版",
        "130":"大师版"
      }
      return subscriptionType[subscriptionInfo.subscription_type];
    },
    subscribeExpiredAt() {
      const expiredAt = this.customerData.subscription_info?.expired_at;
      if (!expiredAt) {
        return '--';
      }

      return moment(Number(expiredAt) * 1000).format("YYYY-MM-DD hh:mm:ss");
    }
  },
  methods: {
    init() {
      this.customerID = this.$route.query.id;
      this.getCustomerData();
      this.getDetailList();
    },
    getCustomerData() {
      this.isLoading = true;
      api.customerInfo({id: this.customerID}).then((res) => {
        if (res.data.code === 10200) {
          res.data.data.accumulation_month = res.data.data.accumulation_month
            ? `${res.data.data.accumulation_month}个月`
            : "";
          if (res.data.data.subscription_expired) {
            res.data.data.subscription_expired = moment(
              new Date(
                Number(`${res.data.data.subscription_expired}000`)
              ).toUTCString()
            ).format("YYYY-MM-DD");
          } else {
            res.data.data.subscription_expired = "无";
          }
          this.customerData = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
        this.isLoading = false;
      });
    },

    getDetailList() {
      switch (this.activeName) {
        // case "积分变动记录":
        //   this.balanceList(this.customerID);
          // break;
        case "购买资产":
          this.assetsList(this.customerID);
          break;
        case "下载记录":
          this.downList(this.customerID);
          break;
        case "交易记录(US)":
          this.transactionRecordsList(this.customerID);
          break;
        case "交易记录(CN)":
          this.transactionRecordsListCN(this.customerID);
          break;
        case "收藏资产":
          this.favoriteList(this.customerID);
          break;
        case "订阅记录":
            this.fetchHistoryList(this.customerID);
            break;
        default:
          break;
      }
    },
    // 收藏资产
    favoriteList(id) {
      const tabIndex = 4;
      let data = {
        customer_id: id,
        limit: this.tabPane[tabIndex].pagesize,
        page: this.tabPane[tabIndex].pageNumber,
      };
      this.tabPane[tabIndex].tableLoading = true;
      api.favoritesList(data).then((resp) => {
        this.tabPane[tabIndex].tableLoading = false;
        if (resp.data.code === 10200) {
          this.tabPane[tabIndex].data = resp.data.data.items;
          this.tabPane[tabIndex].total = parseInt(resp.data.data.total);
        } else {
          this.$$error(resp.data.message);
        }
      });
    },
    // 订阅记录
    fetchHistoryList(id) {
      const tabIndex = 1;
      let data = {
        customer_id: id,
        limit: this.tabPane[tabIndex].pagesize,
        page: this.tabPane[tabIndex].pageNumber,
      };
       this.tabPane[tabIndex].tableLoading = true;
      api.fetchHistoryList(data).then((resp) => {
        this.tabPane[tabIndex].tableLoading = false;
        if (resp.data.code === 10200) {
          this.tabPane[tabIndex].data = resp.data.data.items;
          this.tabPane[tabIndex].total = parseInt(resp.data.data.total);
        } else {
          this.$$error(resp.data.message);
        }
      });
    },
    // 下载记录
    downList(id) {
      const tabIndex = 0;
      let data = {
        customer_id: id,
        limit: this.tabPane[tabIndex].pagesize,
        page: this.tabPane[tabIndex].pageNumber,
      };
      this.tabPane[tabIndex].tableLoading = true;
      api.downloadList(data).then((resp) => {
        this.tabPane[tabIndex].tableLoading = false;
        if (resp.data.code === 10200) {
          this.tabPane[tabIndex].data = resp.data.data.items;
          this.tabPane[tabIndex].total = parseInt(resp.data.data.total);
        } else {
          this.$$error(resp.data.message);
        }
      });
    },
    // 交易记录
    transactionRecordsList(id) {
      const tabIndex = 2;
      let data = {
        limit: this.tabPane[tabIndex].pagesize,
        page: this.tabPane[tabIndex].pageNumber,
        filter: {
          customer_id: id,
          market: "US",
          status: 1
        },
      };
      this.tabPane[tabIndex].tableLoading = true;
      api.orderList(data).then((resp) => {
        this.tabPane[tabIndex].tableLoading = false;
        if (resp.data.code === 10200) {
          this.tabPane[tabIndex].data = resp.data.data.items;
          this.tabPane[tabIndex].total = parseInt(resp.data.data.total);
        } else {
          this.$$error(resp.data.message);
        }
      });
    },
    transactionRecordsListCN(id) {
      const tabIndex = 3;
      let data = {
        limit: this.tabPane[tabIndex].pagesize,
        page: this.tabPane[tabIndex].pageNumber,
        filter: {
          customer_id: id,
          market: "CN",
          status: 1
        },
      };
      this.tabPane[tabIndex].tableLoading = true;
      api.orderList(data).then((resp) => {
        this.tabPane[tabIndex].tableLoading = false;
        if (resp.data.code === 10200) {
          this.tabPane[tabIndex].data = resp.data.data.items;
          this.tabPane[tabIndex].total = parseInt(resp.data.data.total);
        } else {
          this.$$error(resp.data.message);
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    sendMsg() {
      this.msgBus.$emit("userList");
    },
    handleClick() {
      let id = this.$route.query.id;
      if (this.activeName == "下载记录") {
        this.downList(id);
      } else if (this.activeName == "交易记录(US)") {
        this.transactionRecordsList(id);
      } else if (this.activeName == "交易记录(CN)") {
        this.transactionRecordsListCN(id);
      } else if (this.activeName == "收藏资产") {
        this.favoriteList(id);
      } else if (this.activeName == '订阅记录') {
        this.fetchHistoryList(id);
      }
    },
    handleSizeChange(val) {
      let id = this.$route.query.id;
      if (this.activeName == "下载记录") {
        this.tabPane[0].pagesize = val;
        this.downList(id);
      } else if (this.activeName == "交易记录(US)") {
        this.tabPane[2].pagesize = val;
        this.transactionRecordsList(id);
      } else if (this.activeName == "交易记录(CN)") {
        this.tabPane[3].pagesize = val;
        this.transactionRecordsListCN(id);
      } else if (this.activeName == "收藏资产") {
        this.tabPane[4].pagesize = val;
        this.favoriteList(id);
      } else if (this.activeName == '订阅记录') {
        this.tabPane[1].pagesize = val;
        this.fetchHistoryList(id);
      }
    },
    handleCurrentChange(val) {
      let id = this.$route.query.id;
      if (this.activeName == "下载记录") {
        this.tabPane[0].pageNumber = val;
        this.downList(id);
      } else if (this.activeName == "交易记录(US)") {
        this.tabPane[2].pageNumber = val;
        this.transactionRecordsList(id);
      } else if (this.activeName == "交易记录(CN)") {
        this.tabPane[3].pageNumber = val;
        this.transactionRecordsListCN(id);
      } else if (this.activeName == "收藏资产") {
        this.tabPane[4].pageNumber = val;
        this.favoriteList(id);
       } else if (this.activeName == '订阅记录') {
        this.tabPane[1].pageNumber = val;
        this.fetchHistoryList(id);
      }
    },

    changeCustomerStatus() {
      let customer = this.customerData;
      customer.id = this.customerID
      this.$nextTick(() => {
        this.$refs.changeStatusPop.show(customer);
      });
    },
    addPoints() {
      const customer = this.customerData;
      this.$nextTick(() => {
        this.$refs.addPointsPop.show(customer);
      });
    },
    cancelAccount() {
      this.$nextTick(() => {
        this.$refs.cancelAccountPop.show(this.customerID);
      });
    },
    formatHtml(text) {
      return text.replace(/\n/g, "<br/>").replace(/\s/g, "&ensp;");
    },
    getType(val, activeName){
      switch (val) {
        case 1:
          return "个人版老版本"
        case 2:
          return "企业版老版本"
        case 110:
          return "个人版"
        case 120:
          return "专业版"
        case 130:
          return "大师版"
        case 140:
          return "企业版"
        default:
          return ""
      }
    },
    setChannel(val) {//订阅方式 订阅渠道 1，微信，2支付宝 3银行卡 10兑换码 20激活码。30: 99 系统调整
     switch (val.channel) {
        case 1:
          return '微信';
        case 2:
          return '支付宝';
        case 3:
          return '银行卡';
        case 10:
          return '兑换码';
        case 20:
          return '激活码';
        case 30:
          return  '活动赠送'
        case 99:
          return '系统调整';
        default:
          break;
      }
    },
    setCreate(val) {
        return val? this.$moment(new Date(val * 1000)).format('YYYY-MM-DD') : '--';
    }
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less">
@import "scss/customerManagement.less";
</style>
